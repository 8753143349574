<template lang="pug">
div(:id='`dealer_${dealer.id}`' class='text-dark')
  div(class='flex flex-col gap-4 mb-4 lg:flex-row lg:justify-between')
    div(class='space-y-1 leading-none')
      InlineHtml(class='block text-xl font-bold' :text='dealer.name')

      div(v-if='dealer.isRealPro')
        img(src='@/assets/real-pro-logo.svg' width='107' height='20' alt='RealPro')

      div(v-if='dealer.isPreferred' class='flex items-center gap-x-1')
        img(src='/images/preferredDealerIcon.svg' width='16' height='16' alt='Preferred Dealer')
        span(class='text-danger-dark font-bold') Preferred Dealer

      div(v-if='dealer.isDoubleWarranty' class='flex items-center gap-x-1' @click='openDoubleWarrantyModal')
        img(src='/images/doubleWarrantyIcon.svg' width='16' height='16' alt='Double Warranty')
        span(class='text-secondary-dark font-bold') Double Your Warranty
        button(type='button' class='text-info-light underline ml-1') Learn More

    div(v-if='!isInternational' class='leading-tight lg:text-right')
      p(v-if='distance' class='font-bold') {{ distance }}
      a(
        :href='directionsUrl'
        target='_blank'
        rel='noopener, nofollow, noreferrer'
        class='text-info-light'
        @click='emitDealerDirectionsEvent'
      ) Directions

  div(class='flex justify-between items-end gap-x-2')
    div(class='flex-1 space-y-4')
      p(class='capitalize')
        | {{ dealer.address1 }}
        br
        | {{ dealer.city }}, {{ dealer.state }} {{ dealer.zipcode }}
        template(v-if='isInternational')
          br
          | {{ dealer.country }}

      template(v-if='!isExpanded')
        a(v-if='phone' class='inline-block text-inherit underline' :href='`tel:${phone}`' @click='emitDealerPhoneEvent') {{ phone }}

      template(v-else)
        div(v-if='phone || dealer.email || dealer.website')
          p(class='font-bold') Contact Info
          a(v-if='phone' class='block text-inherit underline' :href='`tel:${phone}`' @click='emitDealerPhoneEvent') {{ phone }}
          a(v-if='dealer.email' class='block text-inherit underline' :href='`mailto:${dealer.email}`') {{ dealer.email }}
          a(
            v-if='dealer.website'
            class='block text-inherit underline'
            :href='dealer.website'
            target='_blank'
            rel='noopener, nofollow, noreferrer'
            @click='emitDealerWebsiteEvent'
          ) {{ dealer.website }}

        //- Don't want to show dealers if we don't let them select a brand
        div(v-if='$sitewideConfig.config.dealerLocatorShowBrandFacet && brandGroups')
          img(src='/images/made-by-rt-mark-black.svg' alt='Made by Realtruck' width='156' height='16')
          p(class='font-bold mb-4') Brands Available

          ul(class='flex flex-col gap-x-2 lg:flex-row')
            template(v-for='(brandGroup, index) in brandGroups' :key='index')
              div(class='flex-1')
                template(v-for='(brand, bIndex) in brandGroup' :key='bIndex')
                  li(class='flex items-center gap-x-1')
                    img(
                      v-if='brand.isPreferred'
                      src='/images/preferredDealerIcon.svg'
                      height='16'
                      width='16'
                      :alt='`${brand.brand} Preferred Dealer`'
                    )
                    span(:class='{ "pl-5": !brand.isPreferred }') {{ brand.brand }}

    div(v-if='!isInternational')
      BaseButton(class='p-2' button-style='OUTLINE' color='BLACK' size='SMALL' has-custom-padding @click='toggleDealerCard')
        template(v-if='isExpanded') Less Info &minus;
        template(v-else) More Info +
</template>

<script setup lang="ts">
const { $scrollTo, $uiEvents, $overlay } = useNuxtApp()
const isExpanded = ref(false)
let marker: any
const emit = defineEmits(['focus'])
const dealerData = useDealerLocator()

const {
  dealer,
  map,
  isInternational = false,
} = defineProps<{
  dealer: any
  map?: any
  isInternational?: boolean
}>()

onMounted(async () => {
  if (map) {
    initMarker()
  }

  // Determine if this should be expanded
  isExpanded.value = dealerData.state.value.dealerFocus === dealer.id || !!isInternational
})

onBeforeUnmount(() => {
  if (marker) marker.setMap(null)
})

function initMarker() {
  // Create a local LatLng obj
  const { lat, lng } = dealer._geoloc
  const position = new window.google.maps.LatLng(lat, lng)

  // Create marker instance
  marker = new window.google.maps.Marker({
    position: position,
    clickable: true,
  })

  // Add click handler to the marker
  marker.addListener('click', () => {
    // Focus the marker
    focusMarker('map')
  })

  marker.setMap(map)

  if (dealerData.state.value.dealerFocus === dealer.id) {
    toggleDealerCard()
  }
}

function toggleDealerCard() {
  isExpanded.value = !isExpanded.value

  // If we expanded the dealer card then pan the map to the current dealer.
  if (isExpanded.value) focusMarker('button')
}

function focusMarker(type: string) {
  // Create info window content wrapper
  const wrapper = document.createElement('div')
  wrapper.className = 'text-base'
  wrapper.innerHTML = `
                  <p class="font-bold text-xl mb-2">${dealer.name}</p>
                  <p class="capitalize">${dealer.address1}<br>${dealer.city}, ${dealer.state} ${dealer.zipcode}</p>
              `

  // If dealer has a phone number add a tag
  if (phone.value) {
    const phoneLink = document.createElement('a')
    phoneLink.className = 'block text-inherit underline mt-2'
    phoneLink.href = `tel:${phone.value}`
    phoneLink.innerText = phone.value
    phoneLink.onclick = () => emitDealerPhoneEvent()
    wrapper.appendChild(phoneLink)
  }

  // If dealer has a website, add a tag
  if (dealer.website) {
    const websiteLink = document.createElement('a')
    websiteLink.className = 'block text-inherit underline mt-2'
    websiteLink.href = dealer.website
    websiteLink.target = '_blank'
    websiteLink.rel = 'noopener, nofollow, noreferrer'
    websiteLink.innerText = 'Website'
    websiteLink.onclick = () => emitDealerWebsiteEvent()
    wrapper.appendChild(websiteLink)
  }

  // Create more info button for infowindow and append to wrapper
  const infoWindowButton = document.createElement('button')
  infoWindowButton.className = 'block text-xs p-2 mt-4 mx-auto rounded bg-action font-bold text-white'
  infoWindowButton.innerText = 'MORE INFO +'
  infoWindowButton.onclick = () => {
    // Expand dealer card
    isExpanded.value = true

    // Scroll to this dealer on screen
    $scrollTo(`#dealer_${dealer.id}`, 500, { offset: -70 })
  }
  wrapper.appendChild(infoWindowButton)

  // Emit event
  emit('focus', {
    marker,
    content: wrapper,
  })

  $uiEvents.$emit('dealerLocatorDealerViewed', {
    dealer,
    elementType: type,
  })
}

function emitDealerPhoneEvent() {
  $uiEvents.$emit('dealerLocatorPhoneClicked', {
    dealer,
  })
}

function emitDealerWebsiteEvent() {
  $uiEvents.$emit('dealerLocatorWebsiteClicked', {
    dealer,
  })
}

function emitDealerDirectionsEvent() {
  $uiEvents.$emit('dealerLocatorDirectionsClicked', {
    dealer,
  })
}

function openDoubleWarrantyModal() {
  $overlay.open('doubleWarrantyModal')
}

const brandGroups = computed(() => {
  if (dealer.brands.length === 0) return

  // spread into a new array
  const brands = [...dealer.brands]

  const groups = []

  if (brands.length <= 9) groups.push(brands)
  else {
    const midpoint = Math.floor(brands.length / 2)
    const split = brands.splice(-midpoint)
    groups.push(brands, split)
  }

  return groups
})

const distance = computed(() => {
  // If its international we don't search by geolocation
  // If we don't match a location for whatever reason we can't calculate its location
  if (isInternational || !dealer._rankingInfo?.matchedGeoLocation) return null

  // Distance is in meters from algolia so convert to miles and round to the distance value
  const distanceInMiles = (dealer._rankingInfo.matchedGeoLocation.distance * 0.000621371192).toFixed(2)

  return `${distanceInMiles} mi`
})

const directionsUrl = computed(() => {
  if (isInternational) return null

  // Directions url docs
  // https://developers.google.com/maps/documentation/urls/get-started#directions-action
  return encodeURI(
    `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${dealer.address1}, ${dealer.city}, ${dealer.state} ${dealer.zipcode} ${dealer.country}`
  )
})

const phone = computed(() => {
  // If we don't have one, early out
  if (!dealer.phone) return
  // If international then lets skip the formatting
  if (isInternational) return dealer.phone
  return formatPhoneDash(formatPhoneE164(dealer.phone))
})
</script>
